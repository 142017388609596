.select-base.ant-select-single {
  &.ant-select {
    .ant-select-selector {
      font-size: 14px;
      font-family: 'Poppins-Regular';
      border-radius: 5px;
      align-items: center;
    }
  }

  &.ant-select-disabled {
    .ant-select-selector {
      border-radius: 5px;
      .ant-select-selection-search {
        input {
          width: 100%;
        }
      }
    }
  }
}
