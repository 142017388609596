.version-modal .ant-modal-content {
  border-radius: 16px;
  max-width: 600px;
  padding: 20px;
}

.version-modal .features-container {
  max-height: 80vh;
  overflow-y: auto;
  padding: 16px 0;
  margin-top: 20px;
}

.version-modal .ant-modal-header {
  border-bottom: none;
}

.version-modal .modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.version-modal .ant-modal-close-x {
  position: absolute;
  right: 16px;
  top: 16px;
}

.version-modal ul {
  list-style-type: disc;
  padding-left: 20px;
}

.version-modal li {
  margin-bottom: 10px;
  font-size: 16px;
}

.version-link {
  margin-top: 20px;
  text-align: center;

  & a {
    color: gray;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
  }

  & a:hover {
    color: #0056b3;
  }
}

.version-modal .ant-modal-body {
  padding: 0;
}
