.new-note-modal {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 800px;
  max-height: 555px;
  font-family: 'Poppins';
  font-size: 12px;
  color: var(--primary-text-color);
  gap: 24px;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;

    .column {
      flex: 1;
      flex-direction: column;
      max-width: 345px;

      &.fix-content {
        flex: unset;

        .label-input {
          width: fit-content;

          .ant-select-selector {
            min-width: 180px;
          }
        }
      }

      &.flex-width {
        max-width: unset;
      }

      .label-input {
        display: flex;
        flex-direction: column;

        :disabled {
          background-color: var(--primary-disable-color);
        }

        label {
          margin-bottom: 4px;
          padding-left: 2px;
        }

        input {
          border-radius: 5px;
          padding: 5px 10px;
          color: var(--secondary-text-color);

          &:read-only {
            background-color: white;
          }

          &.ant-select-selection-search-input {
            height: 30px;
            padding: 5px 0;
          }

          &.small {
            width: 100px;
          }
        }

        textarea {
          border-radius: 5px;
          padding: 5px 10px;
          height: 96px;
          color: var(--secondary-text-color);
        }

        .Dropdown-root {
          width: 280px;
        }

        .input-avatar {
          cursor: pointer;

          img {
            border-radius: 5px;
            width: 58px;
            height: 58px;
          }
        }
      }
    }
  }

  @media screen and(max-width: 1024px) {
    width: 94vw;
  }
}

.note-topic-list-modal {
  display: flex;
  flex-direction: row;
  padding: 8px;
  font-size: 12px;
  color: var(--primary-text-color);
  gap: 24px;

  .left-panel {
    width: 385px;
    min-height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .list {
      max-height: 450px;
      overflow-y: auto;
      margin-top: 8px;
      border-radius: 5px;

      .row {
        &.selected {
          background-color: #aaaaaa;
        }
      }
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-top: 8px;

    .action-button {
      justify-content: unset;
      flex-direction: column;
      margin: unset;
    }
  }

  .label-input {
    display: flex;
    flex-direction: column;

    :disabled {
      background-color: var(--primary-disable-color);
    }

    label {
      margin-bottom: 4px;
      padding-left: 2px;
    }

    input {
      border-radius: 5px;
      padding: 5px 10px;
      height: 45px;

      &.small {
        width: 100px;
      }
    }
  }

  .Dropdown-root {
    width: 280px;
  }

  .input-avatar {
    cursor: pointer;

    img {
      border-radius: 5px;
      width: 58px;
      height: 58px;
    }
  }
}

.topic-section {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .add-topic {
    > img {
      cursor: pointer;
    }

    > label {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}

.new-note-topic {
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: fit-content;
  background-color: var(--background-3);
  border-radius: 5px;
  color: var(--color-2);

  .label-input {
    display: flex;
    flex-direction: column;

    :disabled {
      background-color: var(--primary-disable-color);
    }

    > label {
      margin-bottom: 4px;
      padding-left: 2px;

      font-size: 14px;
      font-weight: bold;
    }

    input {
      border-radius: 5px;
      padding: 5px 10px;
      height: 45px;
    }

    .fields {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .checkbox {
        align-items: center;
        display: flex;
        flex-direction: row;

        input[type='checkbox'] {
          margin-right: 8px;
          width: 22px;
          height: 22px;
        }
      }

      input {
        border-radius: 5px;
        padding: 5px 10px;
        height: 35px;
        width: 220px;
      }

      .add-empty-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -4px;

        img {
          cursor: pointer;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.note-topic-choose {
  display: flex;
  flex-direction: column;
  align-items: center;
}
