.confirm-otp-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .confirm-otp-page-header {
    height: 250px;
    width: 100%;
    background-color: var(--background-3);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
      .back-icon {
        width: 40px;
        height: 22px;
        cursor: pointer;
        padding-right: 2px;
      }
    }

    .description {
      width: 350px;
      font-size: 14px;
      font-family: 'Poppins';
      text-align: center;
      line-height: 24px;
      font-weight: 400;
    }

    .note {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .confirm-otp-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    .input-password-area {
      position: relative;

      .input-password-show-btn {
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 40px;
        width: 27px;
        height: 20px;

        &.display {
          top: 44px;
          right: 25px;
          width: 20px;
          height: 11px;
        }
      }
    }

    .input-value {
      width: 396px;
      margin-top: 20px;
      padding: 18px 2px 18px 34px;

      ::placeholder {
        color: var(--secondary-text-color);
      }
    }

    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 44px;
      width: 345px;
      border: none;
      border-radius: 10px;
      margin-top: 37px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
    }
  }

  &.success {
    width: 100%;
    height: 100%;
    background-color: var(--background-2);

    .confirm-otp-page-header {
      background: none;
      color: white;

      .title {
        font-size: 26px;
        font-family: 'Poppins-Bold';
      }

      .description {
        width: 320px;
        font-family: 'Poppins-Medium';
      }
    }

    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 54px;
      width: 214px;
      border: none;
      border-radius: 10px;
      margin-top: 25px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
    }
  }

  .message-container {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #555;
      margin-bottom: 5px;
    }
  }

  .code-input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .code-input {
      width: 71px;
      height: 89px;
      font-size: 24px;
      text-align: center;
      margin: 0 10px; // Adjust margin to match the image
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:focus {
        border-color: #007bff;
      }
    }
  }

  .resend-link {
    width: 100%;
    text-align: left;
    padding-left: 10px;

    a {
      color: #007bff;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
