.table-tab {
  background-color: var(--background-2);
  color: var(--table-text-color);
  width: 100%;
  padding: 8px;
  height: 36px;
  display: flex;
  justify-content: flex-start;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  &-item {
    margin: 0 12px;
    cursor: pointer;
  }
  &-item.select {
    text-decoration: underline;
  }
}

.reject-tech-notification {
  display: flex;
  padding: 8px;
  background: #fee6e9;
  border-radius: 8px;
  gap: 8px;
  width: fit-content;
  color: black;

  svg {
    margin-top: 4px;
  }

  p {
    margin: 0;
  }
}

.select-tab {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  border-bottom: 1px solid var(--modal-header-color);
  color: var(--primary-text-color);

  &-item.selected {
    border-bottom: 3px solid var(--modal-header-color);
  }
}

.password-rule-popup {
  z-index: 9999;
  position: relative;
  width: 100%;
  min-width: 360px;

  &-content {
    position: absolute;
    width: 100%;

    .password-rule {
      margin: 10px 30px;
      padding: 10px 20px;
      background: var(--background-3);
      border-radius: 8px;
      position: absolute;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      label,
      li {
        color: black !important;
        font-size: 12px !important;
      }

      .arrow {
        position: absolute;
        top: 0;
        height: 24px;
        width: 24px;
        transform: translateY(-50%) rotate(45deg);
        background: var(--background-3);
        outline: none;
        border: none;
      }
    }
  }
}
