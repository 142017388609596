code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --background-1: #33a7d8;
  --background-2: #a9ceeb;
  --background-3: #fff;
  --background-4: #d0d0d0;

  --background-1-alpha50: rgba(28, 38, 133, 0.9);

  --color-1: #33a7d8;
  --color-2: #a9ceeb;

  --primary-text-color: #4f4f4f;
  --secondary-text-color: #505050;

  --primary-disable-color: #d0d0d0;

  --primary-btn-color: #33a7d8;
  --primary-btn-text-color: #fff;

  --secondary-btn-color: #fff;
  --secondary-btn-text-color: #000;

  --tertiary-btn-color: #2aaa8a;
  --tertiary-btn-text-color: #fff;

  --table-header-color-1: #33a7d8;
  --table-header-text-1: #fff;
  --table-body-text-1: #4f4f4f;

  --up: #1ad598;
  --down: #ea3a3d;

  --up-alpha-20: rgba(26, 213, 152, 0.2);
  --down-alpha-20: rgba(234, 58, 61, 0.2);

  --input-in-white-bg: #eaeaea;

  /* Booking */
  --booking-background: #b3d6f2;
  /* Booking */

  /* Dashboard */
  --dashboard-background: #fff;
  --dashboard-summary-color-text: var(--primary-text-color);
  --dashboard-summary-background: var(--background-3);
  --dashboard-summary-border: 1px solid #cdcdcd;
  /* Dashboard */

  /* Finance */
  --finance-background: #fff;
  /* Finance */

  /* Feedback */
  --feedback-star: #e1cc55;
  --feedback-tab-color: #33a7d8;
  --feedback-leftpanel-color: #ffffff;
  --feedback-rightpanel-color: var(--primary-text-color);
  --delete-review: #c70202;
  /* Feedback */

  /* Invoice */
  --invoice-background: #daecfa;
  /* Invoice */

  /* Modal */
  --modal-background: #e0effa;
  --modal-header-color: var(--color-1);
  --modal-header-close-color: var(--color-1);
  /* Modal */

  /* Noti */
  --high-priority-color: #ff0000;
  --low-priority-color: #518ae5;
  /* Noti */

  /* Quote */
  --quote-background: #bfddf4;
  --attach-quote-btn-bg: #0097d8;
  --attach-quote-btn-text-color: var(--primary-text-color);
  /* Quote */

  /* Task */
  --task-background: #bfddf4;
  /* Task */

  /* Setting */
  --setting-background: #bfddf4;
  /* Setting */

  /* Table */
  --table-text-color: #000;
  --table-row-separate-color: #33a7d8;
  --table-bg-color: #a9ceeb;
  /* Table */

  /* Leftbar */
  --tooltip-background: #bfddf4;
  --tooltip-color: var(--primary-text-color);
  /* Table */

  --border-color-1: #4f4f4f;

  /* Checkbox */
  --primary-checkbox-color: #e75a7c;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar:horizontal {
  height: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #b9b7b7;
  border: 1px solid #9c9c9c;
}
