.feedback-page-info {
  width: 100%;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  color: var(--feedback-tab-color);
  padding: 6px 15px;
  max-height: 555px;
  .react-tabs {
    .react-tabs__tab-list {
      .react-tabs__tab {
        height: 40px;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 600;
        color: var(--feedback-tab-color);
      }
      .react-tabs__tab--selected {
        color: var(--feedback-tab-color);
        font-weight: bold;
        background-color: transparent;
        border: none;
        font-size: 16px;
      }
      .react-tabs__tab--selected:after {
        content: '';
        position: absolute;
        height: 5px;
        left: 16px;
        right: 16px;
        bottom: 0px;
        background: var(--feedback-tab-color);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    .react-tabs__tab-panel {
      .feedback-page-content {
        display: flex;
        flex-direction: row;
        .left-panel {
          width: 35%;
          background-color: var(--background-1);
          border-radius: 8px;
          padding-left: 16px;
          padding-right: 16px;
          color: var(--feedback-leftpanel-color);
          .panel-title {
            font-size: 12px;
            font-weight: bold;
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--feedback-leftpanel-color);
          }
          .no-review-found {
            width: 100%;
            padding-top: 16px;
          }
          .list-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 443px;
            .list-wrapper-item {
              width: 100%;
              border-bottom: 1px solid var(--feedback-leftpanel-color);
              padding-top: 12px;
              padding-bottom: 12px;
              .list-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                .avatar {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  object-fit: cover;
                }
                .content {
                  flex-grow: 1;
                  margin-left: 10px;
                  display: flex;
                  flex-direction: column;
                  .content-part {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    .header-left {
                      flex-grow: 1;
                      display: flex;
                      flex-direction: column;
                      margin-left: 10px;
                    }
                    .header-right {
                      display: flex;
                      flex-direction: column;
                      margin-left: 16px;
                      text-align: right;
                    }
                    .feedback-date {
                      font-size: 12px;
                      flex-grow: 1;
                    }
                    .feedback-rating {
                      .feedback-rating-wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        .feedback-rating-reated {
                          color: var(--feedback-star);
                        }
                        .feedback-rating-normal {
                          font-size: 12px;
                          margin-left: 2px;
                          margin-right: 2px;
                        }
                      }
                      .feedback-delete {
                        color: white;
                        margin-top: 8px;
                        font-size: 12px;
                        cursor: pointer;
                        background-color: var(--delete-review);
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                      }
                    }
                    .feedback-user-name {
                      font-size: 12px;
                    }
                    .feedback-comment {
                      font-size: 12px;
                      max-height: 40px;
                      padding-right: 8px;
                      margin: 8px 0 0 10px;
                      overflow-y: hidden;
                    }
                  }
                }
              }
            }
            .list-wrapper-item-selected {
              background-color: var(--background-2);
            }
          }
          .panel-paging {
            width: 100%;
            margin-left: -40px;
            margin-top: 20px;
          }
        }
        .right-panel {
          height: 555px;
          margin-left: 8px;
          width: 63%;
          background-color: var(--background-2);
          border-radius: 8px;
          color: var(--feedback-rightpanel-color);
          .feedback-info {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .avatar {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }
            .info {
              font-size: 40px;
              font-weight: bold;
            }
            .info-detail {
              font-size: 14;
            }
          }
          .feedback-detail {
            padding: 16px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .header {
              display: flex;
              flex-direction: row;
              padding-bottom: 16px;
              border-bottom: 1px solid var(--feedback-rightpanel-color);
              color: var(--feedback-rightpanel-color);
              .avatar {
                width: 80px;
                height: 80px;
                border-radius: 5px;
              }
              .header-left {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                .feedback-user-name {
                  font-size: 14px;
                }
                .review-for {
                  font-size: 14px;
                  margin-top: 8px;
                  font-weight: bold;
                  color: var(--background-1);
                }
                .feedback-date {
                  font-size: 14px;
                  margin-top: 8px;
                }
              }
              .header-right {
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                text-align: right;
                .feedback-technician {
                  font-size: 14px;
                  margin-bottom: 8px;
                }
                .feedback-rating {
                  .feedback-rating-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    .feedback-rating-reated {
                      color: var(--feedback-star);
                    }
                    .feedback-rating-normal {
                      font-size: 16px;
                      margin-left: 2px;
                      margin-right: 2px;
                    }
                  }
                }
                .feedback-delete {
                  color: white;
                  margin-top: 8px;
                  font-size: 12px;
                  cursor: pointer;
                  background-color: var(--delete-review);
                  border-radius: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
            .content {
              flex: 1;
              margin-top: 16px;
              padding-bottom: 16px;
              border-bottom: 1px solid var(--feedback-rightpanel-color);
              color: var(--feedback-rightpanel-color);
            }
          }
        }
      }
    }
  }
}
