.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .login-page-header {
    position: relative;
    height: 250px;
    width: 100%;
    background-color: var(--background-3);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100vw;
      background-color: var(--background-3);
      z-index: -1;
    }

    .login-page-header-image {
      background-image: url('./../images/login-header-image.png');
      width: 360px;
      height: 250px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    .input-value {
      width: 396px;
      margin-top: 20px;

      ::placeholder {
        color: var(--secondary-text-color);
      }
    }
    .login-remember-forgot {
      margin-top: 17px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 10px;
      color: var(--primary-text-color);

      a {
        color: var(--primary-text-color);
      }
    }

    button {
      background-color: var(--primary-btn-color);
      color: var(--primary-btn-text-color);
      height: 50px;
      width: 330px;
      border: none;
      border-radius: 10px;
      margin-top: 37px;
      font-size: 16px;
      font-family: 'Poppins-Medium';

      &:hover span {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .login-page {
    .login-form {
      > div {
        width: 100% !important;
        .input-value {
          width: 100% !important;
        }
      }
    }
  }
}
