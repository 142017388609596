.promote-modal {
  .row {
    flex-wrap: wrap;
    align-items: center;
  }

  .label-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 10px;
    padding: 0 20px;
    width: 450px;

    &.select-category {
      width: 220px;
      margin: 10px 0px;
      padding: 0 10px;
    }

    &.select-category:first-child {
      margin: 10px 0px 10px 10px;
      padding: 0 10px 0 20px;
    }

    &.col {
      margin-top: 20px;
    }

    label {
      color: var(--primary-text-color);
      font-size: 12px;
      font-family: 'Poppins-Medium';
      min-width: 190px;
      margin-top: 8px;
    }
    input,
    select {
      border-radius: 5px;
      padding: 5px 10px;
      height: 45px;
      width: 100%;

      &:disabled {
        border-radius: 5px;
      }
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
      }
    }

    .promo-amount {
      width: 100%;

      > div {
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 16px;
        .ant-input-number {
          width: 100%;
        }
      }

      &-half {
        display: flex;
        gap: 8px;
      }
    }

    .promo-qty-availabel {
      width: 100%;

      > div {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 16px;
      }
    }

    &.flex-row {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .ant-picker {
      input:read-only {
        background-color: inherit;
      }
      input:disabled {
        background-color: var(--primary-disable-color);
      }
    }

    .ant-input-number {
      border-radius: 5px;
    }
  }

  .add-applicable-label {
    display: block;
    margin: 10px;
    padding: 0 20px;
    font-weight: bold;
    color: var(--primary-text-color);
  }

  .add-more-service {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin: 10px;
    padding: 20px;
    color: var(--primary-text-color);

    img {
      cursor: pointer;
    }
  }

  .delete-service {
    align-self: flex-end;
    cursor: pointer;
  }

  &-btn {
    margin-left: 30px;

    .primary {
      background-color: var(--primary-btn-color);
      border: none;
      color: #ffffff;
      width: 250px;
      height: 40px;
      padding: 8px 0px;
      border-radius: 5px;
      cursor: pointer;
    }

    .link-btn {
      font-size: 11px;
      font-family: 'Poppins-Medium';
      color: var(--primary-text-color);
      text-decoration: underline;
      padding-right: 30px;
      cursor: pointer;
      background: inherit;
      border: none;
    }
  }
}
